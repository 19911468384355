<template>
  <vue-modal
    ref="modal"
    size="md"
    @close="$emit('close')"
  >
    <template slot="title">
      {{ role.name }} Users List
    </template>
    <template slot="body">
      <Loader
        v-if="loading"
        class="mx-auto d-block"
      />
      <div
        v-else
        class="list-group list-group-flush"
      >
        <p v-if="!users.length">
          No users to show
        </p>
        <template v-else>
          <div
            v-for="user of users"
            :key="user.user_id"
            class="list-group-item"
          >
            {{ user.name }} <small>-  {{ user.email }}</small>

            <md-button
              class="float-right md-icon md-sm md-simple md-primary"
              @click="onEdit(user)"
            >
              <md-icon>person</md-icon>
              <md-tooltip>Go to details</md-tooltip>
            </md-button>

            <md-button
              v-if="userIsFullAdmin"
              class="float-right md-icon md-sm md-simple md-info"
              @click="onLogAsUser(user)"
            >
              <md-icon>key</md-icon>
              <md-tooltip>Log In as User</md-tooltip>
            </md-button>
          </div>
        </template>
      </div>
    </template>
    <template slot="footer">
      <md-button
        class="md-danger"
        @click="close"
      >
        Close
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import Loader from '@/components/loader';

export default {
  components: {
    Loader,
  },
  props: {
    role: {
      type: Object,
      default: null,
      required: true,
    },
  },
  data: () => ({
    loading: true,
    users: [],
  }),
  mounted() {
    this.getUsers();
  },
  methods: {
    getUsers() {
      this.request(`roles/${this.role.role_id}`, 'get', {
        just_users: true,
      }, ({ data }) => {
        this.users = data.users;
        this.loading = false;
      });
    },
    close() {
      this.$refs.modal.close();
    },
    onEdit(item, isMiddleClick) {
      this.openLink({ name: 'EditUser', params: { id: item.user_id } }, isMiddleClick);
    },
    onLogAsUser({ user_id }) {
      this.requestAsync(this.$API.LOGIN + user_id, 'post', {})
        .then((data) => {
          this.setAdminSession();
          return data;
        })
        .then((data) => {
          this.setSession(data);
          this.$router.push('/');
        });
    },
  },
};
</script>
<style></style>
