<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-just-icon md-round md-primary pull-right"
              @click="showModal = true"
            >
              <md-icon>add</md-icon>
              <md-tooltip>New Role</md-tooltip>
            </md-button>

            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @onShowDetails="onShowDetails"
              @onDelete="onDelete"
              @onShowList="onShowList"
              @changed="getRoles"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>

    <roles-form
      v-if="showModal"
      :role="selectedRole"
      @close="onCloseModal"
      @onSave="$refs.vtable.init()"
    />

    <RolesList
      v-if="showList"
      :role="selectedRole"
      @close="showList = false"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import RolesForm from './RolesForm.vue';
import RolesList from './RolesList.vue';

export default {
  components: {
    VueTable,
    RolesList,
    RolesForm,
  },
  props: [],
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'name',
            sortable: true,
          },
          {
            title: 'slug_name',
            mask: 'slug name',
            sortable: true,
          },
          {
            title: 'description',
            sortable: true,
          },
        ],
        actions: [
          {
            buttonClass: 'md-primary',
            tooltip: 'Show Role Users',
            callback: 'onShowList',
            icon: 'people',
          },
          {
            buttonClass: 'md-warning',
            tooltip: 'Show Role Details',
            callback: 'onShowDetails',
            icon: 'search',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Delete Role',
            callback: 'onDelete',
            icon: 'close',
          },
        ],
        values: {},
      },
      selectedRole: null,
      showModal: false,
      showList: false,
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getRoles(params) {
      this.request(this.$API.ROLES.paginableUrl(params), 'get', null, ({ data }) => {
        this.vTable.values = data;
      });
    },
    onShowDetails(item) {
      this.showModal = true;
      this.selectedRole = item;
    },
    onShowList(item) {
      this.showList = true;
      this.selectedRole = item;
    },
    onDelete(item) {
      this.fireConfirm(
        'Deleting Role',
        'Are you sure you want to delete this Role?',
      ).then(() => {
        this.request(this.$API.ROLES + item.role_id, 'delete', null, () => {
          this.$refs.vtable.init();
          this.fireSuccess('Role deleted successfully.');
        });
      });
    },
    onCloseModal() {
      this.showModal = false;
      this.selectedRole = null;
    },
  },
};
</script>

<style scoped>
</style>
